.buttons {
    position: absolute;
    bottom: 20px;
    display: flex;
    flex-direction: row;
    gap: 20px;
    justify-content: space-between;
    transform: translateX(-50%);
    left: 50%;

    & button {
        height: 45px;
        width: 140px;
        display: flex;
        justify-content: center;
        background-color: rgb(0, 110, 255);
        font-size: 20px;
        font-weight: 400;
    }

    & button:disabled,
    button[disabled] {
        // background-color: rgb(54, 129, 228);
        background-color: rgb(196, 227, 253);
        box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.4);
        color: rgb(0, 110, 255);

        &:hover {
            // background-color: rgb(54, 129, 228);
            background-color: rgb(196, 227, 253);
            box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.4);
        }
    }

    & button:hover {
        background-color: rgb(60, 145, 255);
        box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.4);
    }
}
