.main {
    height: 90%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    gap: 20px;

    & .theme {
        font-size: 32px;
        font-weight: 500;
    }

    & .container {
        font-size: 18px;
        width: calc(100% - 20px);
        height: 75%;
        background-color: whitesmoke;
        border-radius: 5px;
        // color: white;
    }

    & .buttons {
        bottom: 0;
        height: 25%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        flex-direction: column;
        gap: 10px;
        margin-bottom: 20px;

        & .update {
            display: flex;
            flex-direction: row;
            width: 100%;
            justify-content: center;
            gap: 10px;
        }
        & .button {
            height: 40px;
            width: 250px;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: rgb(0, 110, 255);
        }
    }
}
