.container {
    height: 100%;

    & .empty {
        width: 100%;
        padding: 20px;
        height: 100%;
        display: flex;
        justify-content: center;

        & span {
            color: black;
            font-size: 18px;
            font-weight: 600;
        }
    }

    & .not_empty {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        padding: 20px 10px;
        overflow-y: auto;
        gap: 15px;
    }
}
