* {
    scrollbar-color: rgba(51, 135, 247, 0.9) rgba(34, 126, 247, 0.342);
    scrollbar-width: thin;

    &::-webkit-scrollbar {
        /* display: none; */
        width: 6px;
        height: 3px;
    }
    &::-webkit-scrollbar-track {
        border-radius: 10px;
        background-color: rgba(34, 126, 247, 0.342);
    }
    &::-webkit-scrollbar-thumb {
        transition: background-color 0.15s;
        border-radius: 10px;
        background-color: rgba(24, 122, 250, 0.671);
    }
    &::-webkit-scrollbar-thumb:hover {
        background-color: rgba(51, 135, 247, 0.75);
    }
    &::-webkit-scrollbar-thumb:active {
        background-color: rgba(51, 135, 247, 0.9);
    }
}
