.root {
    width: 400px;
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.expected_response {
    width: 100%;
    align-self: center;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 5px;

    & .header {
        text-align: start;
        font-size: 12px;
        font-weight: normal;
    }

    & .list {
        display: flex;
        flex-direction: row;
        width: 100%;
        gap: 5px;
        overflow-y: hidden;
        padding: 2px 5px;

        & .list_item {
            white-space: nowrap;
            background-color: rgb(51, 135, 247);
            padding: 5px 10px;
            border-radius: 2px;
            margin: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.25);

            & p {
                font-size: 12px;
                font-weight: normal;
            }
        }
    }
}

.questionBlock {
    justify-content: space-between;
    background-color: rgb(51, 135, 247);
    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.25);
    align-self: center;
    // justify-content: center;
    width: 400px;
    height: 50px;
    // padding: 5px;
    border-radius: 5px;
    // margin-top: 10px;
    min-height: 50px;

    & .question_block_content {
        display: flex;
        height: 100%;
        flex-direction: row;
        gap: 10px;
        justify-content: space-between;
        align-items: center;
        padding: 0 5px 0 0;

        & .question_block_content_message {
            background-color: rgb(188, 217, 255);
            flex: 1;
            padding: 0;
            margin: 0;
            display: flex;
            height: 100%;
            align-items: center;
            justify-content: flex-start;
            align-items: center;
            border-radius: 5px;
            overflow: hidden;

            & p {
                color: rgb(1, 62, 143);
                margin: 0 5px;
                text-align: start;
            }
            & textarea {
                width: 100%;
                color: rgb(1, 62, 143);
                height: 100%;
                border-width: 0;
                font-size: 15px;
                resize: none;

                /* margin: 0 5px; */
                text-align: start;
            }
        }

        & .icon {
            width: 25px;
            height: 25px;
            transition: transform 0.3s, color 0.3s;
            cursor: pointer;
            color: white;

            &:hover {
                transform: scale(0.9);
            }
        }
    }

    & .mic_container {
        background-color: rgb(0, 110, 255);
        transition: background-color 1s;
        padding: 5px;
        border-radius: 50%;
    }

    & .mic_container_active {
        background-color: rgb(255, 57, 90);
        animation: rec 1.5s ease infinite;
    }
}

@keyframes rec {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.2);
    }
    100% {
        transform: scale(1);
    }
}
