@import "../style.module.scss";

.container {
    width: 520px;
    padding: none;

    & .header {
        font-size: 24px;
        font-weight: 500;
    }

    & .edit {
        position: absolute;
        top: 10px;
        right: 50px;

        &:hover {
            cursor: pointer;
        }
    }
}

.point_out {
    &__true {
        background-color: #22c55e;
    }

    &__false {
        background-color: #ec486b;
    }
}

.example-enter {
    opacity: 0.01;
}

.example-enter.example-enter-active {
    opacity: 1;
    transition: opacity 500ms ease-in;
}

.example-leave {
    opacity: 1;
}

.example-leave.example-leave-active {
    opacity: 0.01;
    transition: opacity 300ms ease-in;
}
