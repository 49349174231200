.container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
}

.main {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;

    & .text_container {
        width: 90%;
        display: flex;
        flex-direction: column;
        gap: 10px;

        & .reading_text_container {
            background-color: gray;
            height: 300px;
        }
        & p {
            font-size: 16px;
            font-weight: 400;
            word-wrap: break-word;
        }
        & .title {
            font-size: 18px;
            font-weight: 600;
            text-align: center;
        }
        & .author {
            text-align: center;
            font-size: 13px;
            margin-bottom: 20px;
        }
        & .text_lenght {
            text-align: end;
            font-size: 13px;
        }
    }

    & .timer {
        position: absolute;
        bottom: 30px;
        right: 20px;
        font-size: 24px;
        font-weight: 600;
    }
}

.result {
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: flex-start;
    justify-content: flex-start;
    height: 80%;
    width: 400px;
    margin-right: 20px;

    & .header {
        font-size: 20px;
        font-weight: 500;
    }
    & .stats {
        display: flex;
        flex-direction: column;
        gap: 5px;

        & p {
            font-size: 18px;
        }
    }
}

.added {
    color: green;
    background-color: #b5efdb;
}

.removed {
    color: red;
    background-color: #fec4c0;
}

.not_read {
    background-color: #ecf87d;
}

.result_text span {
    line-height: 20px;
    font-size: 16px;
}
@media (max-height: 800px) {
    .result_text {
        unicode-bidi: bidi-override;
        direction: rtl;
        height: 250px;
        overflow-y: auto;
        text-align: left;
        padding: 5px;

        & span {
            unicode-bidi: bidi-override;
            direction: ltr;
        }
    }
}

.button {
    padding: 20px;
}
