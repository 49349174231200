.info_container {
    margin-top: 20px;
    width: 100%;
    min-height: 50px;
    display: flex;
    flex-direction: column;
    gap: 20px;

    & .title {
        width: 100%;

        & span {
            font-size: 20px;
            font-weight: 600;
        }
    }

    & .stat_info {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        & .author {
            display: flex;
            flex-direction: row;
            gap: 10px;
            align-items: center;
            text-decoration: inherit;
            color: inherit;

            & .avatar {
                width: 50px;
                height: 50px;
                background-color: rgba($color: #000000, $alpha: 0.1);
                border-radius: 50%;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            & span {
                font-weight: 500;
            }
        }

        & .stats {
            display: flex;
            flex-direction: row;
            height: 100%;
            align-items: center;
            gap: 20px;

            & svg {
                width: 25px;
                height: 25px;
            }

            & .stat {
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: 5px;

                & span {
                    font-size: 14px;
                    font-weight: 500;

                    & .from_service {
                        color: rgba($color: #000000, $alpha: 0.5);
                    }
                }

                & .like {
                    $color: rgba(
                        $color: #fc2d2d,
                        $alpha: 0.9,
                    );

                    transition: fill 0.4s ease;

                    color: $color;

                    &__selected {
                        fill: $color;
                    }

                    &__updating {
                        opacity: 0.8;
                        fill: rgba($color: $color, $alpha: 0.5);
                    }

                    &:hover {
                        cursor: pointer;
                        fill: rgba($color: $color, $alpha: 0.2);
                    }
                }

                & .view {
                    color: #006eff;
                }
            }
        }
    }

    & .description_container {
        display: flex;
        flex-direction: column;
        gap: 10px;

        & .description {
            white-space: pre-line;
            font-size: 16px;
            line-height: 21px;

            &__hide {
                height: 42px;
                display: -webkit-box;
                -webkit-line-clamp: 2; // <- you can change rows number
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }

        & .full {
            display: flex;
            align-items: center;
            height: 25px;

            & span {
                font-size: 14px;
                font-weight: 500;
            }
        }
    }
}
