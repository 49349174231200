.modal {
    z-index: 1000001;
    position: fixed;
    top: 50%;
    left: 50%;
    width: 90%;
    max-width: 1200px;
    height: 90%;
    max-height: 850px;
    transform: translate(-50%, -50%);
    background-color: white;
    border-radius: 10px;
    overflow-y: hidden;
    overflow-x: visible;
}

.modal_container {
    z-index: 1000000;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba($color: #010101, $alpha: 0.2);
}
