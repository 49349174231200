@import "../style.module.scss";

.solution_container {
    width: 100%;

    & .button {
        padding: 10px;
        height: auto;
        width: auto;
        font-size: 20px;

        background-color: #23a6d5;
        display: flex;
        flex-direction: row;
        gap: 10px;
    }

    & .content {
        display: flex;
        flex-direction: column;
        gap: 20px;

        & .header {
            display: flex;
            flex-direction: row;
            width: 100%;
        }
    }
}
