$size: 10px;

.container {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    .dots_container {
        position: relative;
        height: 10px;
        display: flex;
        flex-direction: row;

        & .dot {
            position: absolute;
            width: $size;
            height: $size;
            border-radius: 50%;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            background-color: rgb(180, 219, 255);
            
            &__1 {
                left: -20px;
                animation: dot_animate 1.25s;
                animation-iteration-count: infinite;
            }
            &__2 {
                animation: dot_animate 1.25s;
                animation-iteration-count: infinite;
                animation-delay: 0.25s;
            }
            &__3 {
                left: 20px;
                animation: dot_animate 1.25s;
                animation-iteration-count: infinite;
                animation-delay: 0.50s;
            }
        }
    }
}

@keyframes dot_animate {
    30% {
        width: $size + 5px;
        height: $size + 5px;
    }
    100% {
        width: $size;
        height: $size;
    }
}
