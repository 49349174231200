.main {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.result {
    margin: 200px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    width: 700px;
    font-size: 20px;
    gap: 30px;
}
.result .header {
    font-size: 22px;
    font-weight: bold;
}
.result .button {
    width: 300px;
}

.test {
    margin: 200px 0;
    display: flex;
    flex-direction: column;
    align-items: center;

    width: 700px;
    font-size: 20px;
    gap: 30px;
}

.test .button {
    margin-top: 40px;
    width: 400px;
}
