.root {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;

    & .question {
        width: 80%;
        text-align: center;
        font-size: 20px;
        font-weight: 500;
    }

    & .description {
        width: 80%;
        text-align: center;
        font-size: 16px;
    }

    & .baskets {
        margin-top: 70px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        & .container {
            height: 300px;
            display: flex;
            flex-direction: column-reverse;
            justify-content: space-between;
            align-items: center;

            & .main {
                position: relative;
                display: flex;
                width: 90%;
                flex-direction: row;
                gap: 50px;

                & .current_item_container {
                    width: 200px;
                    height: 300px;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;

                    & .item_name {
                        background-color: rgb(166, 199, 243);
                        padding: 10px 20px;
                        border-radius: 20px;

                        & p {
                            font-size: 18px;
                            font-weight: 600;
                            margin: 0;
                            padding: 0;
                            text-align: center;
                        }
                    }

                    & .item_container {
                        position: relative;
                        width: 200px;
                        height: 200px;

                        & div {
                            position: absolute;
                        }
                    }

                    & .void_container {
                        width: 200px;
                        height: 300px;
                    }
                }
            }
        }
    }
}
