.root {
    width: 200px;
    height: 200px;
    position: relative;

    & .basket {
        transform: translate(-50%, 55%);
        left: 50%;
        transition: width 0.2s;
        position: absolute;
        bottom: 0;
        z-index: 1000;
    }

    & .description {
        width: 100%;
        position: absolute;
        bottom: -60%;
        // width: 120%;
        text-align: center;
        font-size: 20px;
        color: rgb(91, 158, 247);
    }
}
