.root {
    position: absolute;
    background-color: #f0f0f0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 20px;
    z-index: 100;
    
    & p {
        text-align: center;
        font-size: 26px;
        font-weight: 700;
        width: 80%;
        color: cadetblue;
    }

    & .buttons_container {
        display: flex;
        flex-direction: row;
        width: auto;
        gap: 20px;

        & .button {
            display: flex;
            justify-content: center;
            width: 150px;

            &__yes {
            }
            &__no {
                background-color: rgb(0, 110, 255);
            }
        }
    }
}
