.root {
    position: relative;
    width: 700px;
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
}

.overlay_test {
    background-color: red;
    display: none;
}
