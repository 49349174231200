.main {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    gap: 20px;

    & .test {
        position: fixed;
        top: 0;
        left: 0;
    }

    & .modal_container {
        position: fixed;
        width: 100%;
        height: 100%;
        background-color: rgba($color: #000000, $alpha: 0.15);
        transform: translate(-50%, -50%);
        top: 50%;
        left: 50%;

        & .modal_view {
            font-size: 20px;

            width: 400px;
            height: 200px;
            background-color: white;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            border-radius: 5px;
            box-shadow: 0px 0px 10px rgba($color: #000000, $alpha: 0.2);
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            gap: 60px;

            & .buttons {
                display: flex;
                gap: 20px;

                & .yes {
                    background-color: rgb(0, 110, 255);
                }

                & button {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: 40px;
                    width: 100px;
                }
            }
        }
    }

    & .theme {
        font-size: 32px;
        font-weight: 500;
        padding: 0 1rem;
    }

    & .not_found {
        width: 100%;
        font-size: 32px;
        text-align: center;
    }

    & .container {
        font-size: 18px;
        width: 100%;
        height: 75%;
        position: relative;

        & .loader {
            // max-height: 550px;
            height: 100%;
        }
    }

    & .buttons {
        height: 110px;
        min-height: 110px;
        max-height: 110px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 30px;
        gap: 20px;

        @media (max-width: 1280px) {
            margin-bottom: 3rem;
        }

        & .button {
            height: 40px;
            width: 250px;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: rgb(0, 110, 255);
        }

        .control_container {
            @media (max-height: 800px) {
                margin-bottom: 60px;
            }
            padding: 5px 15px;
            border-radius: 10px;
            box-shadow: 0 0 5px rgba($color: #000000, $alpha: 0.15);
            background-color: rgba($color: #000000, $alpha: 0.15);
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 10px;

            & .icon_container {
                height: 40px;
                width: 40px;
                display: flex;
                align-items: center;
                justify-content: center;

                & .icon {
                    $width: 30px;
                    height: $width;
                    width: $width;
                    transition: height 0.1s, width 0.1s, color 0.1s, fill 0.1s;

                    &__play {
                        color: rgb(255, 57, 90);
                        fill: rgb(255, 57, 90);
                    }

                    &__pause {
                        color: rgb(0, 110, 255);
                        fill: rgb(0, 110, 255);
                    }

                    &:hover {
                        cursor: pointer;
                        transform: scale(0.9);
                    }
                }
                &__disable {
                    & .icon {
                        &:hover {
                            transform: none;
                        }
                    }
                }
            }

            & .mic_container {
                $width: 30px;
                height: $width;
                width: $width;
                background-color: rgb(0, 110, 255);
                transition: background-color 1s;
                padding: 5px;
                border-radius: 50%;

                &__active {
                    background-color: rgb(255, 57, 90);
                    animation: rec 1.5s ease infinite;
                }

                & .icon {
                    width: 25px;
                    height: 25px;
                    color: white;
                }
            }
        }
    }
}

@keyframes rec {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.2);
    }
    100% {
        transform: scale(1);
    }
}
