.main {
    width: 100%;
    // height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    gap: 20px;

    & .image_container {
        width: 80%;
        display: flex;
        flex-direction: column;
        gap: 10px;

        & img {
            width: 100%;
            border-radius: 5px;
            max-height: 478px;
            @media (max-height: 800px) {
                max-height: 320px;
                width: auto;
            }
        }

        & p {
            font-size: 14px;
        }
    }

    & .words {
        display: flex;
        flex-direction: column;
        gap: 20px;
        width: 115px;

        @media (max-height: 800px) {
            gap: 10px;
        }

        & .scores {
            display: flex;
            flex-direction: row;
            align-items: center;

            & .fail {
                color: red;
            }

            & .success {
                color: seagreen;
            }
        }

        & p {
            text-align: end;
            font-size: 16px;
            font-weight: 500;
            text-transform: capitalize;
            @media (max-height: 800px) {
                font-size: 14px;
            }
        }

        & .selected_word {
            color: seagreen;
        }

        & div {
            display: flex;
            flex-direction: column;
            gap: 3px;
        }
    }

    & .buttons {
        position: absolute;
        bottom: 20px;
        display: flex;
        flex-direction: row;
        gap: 20px;
        justify-content: space-between;

        & button {
            height: 45px;
            width: 140px;
            display: flex;
            justify-content: center;
            background-color: rgb(0, 110, 255);
            font-size: 20px;
            font-weight: 400;
        }

        & button:disabled,
        button[disabled] {
            // background-color: rgb(54, 129, 228);
            background-color: rgb(196, 227, 253);
            box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.4);
            color: rgb(0, 110, 255);

            &:hover {
                background-color: rgb(54, 129, 228);
                box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.4);
            }
        }

        & button:hover {
            background-color: rgb(60, 145, 255);
            box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.4);
        }
    }
}
