@import "../../style.modules.scss";
@import "../style.module.scss";

.wrapper {
    & .stop_button {
        position: absolute;
        top: 0;
        left: 120px;
        transform: translateY(-50%);
        display: flex;
        justify-content: center;
        width: 100px;
    }

    & .buttons {
        position: absolute;
        top: 0;
        left: 130px;
        transform: translateY(-50%);
        display: flex;
        flex-direction: row;
        gap: 10px;

        & button {
            display: flex;
            justify-content: center;
            padding: 0 15px;
            gap: 5px;
        }

        & .blue_button {
            background-color: #006eff;
        }

        & .stop {
            & svg {
                fill: #fff;
            }
        }
    }
}

.likes {
    display: flex;
    flex-direction: column;
    gap: 10px;

    & span {
        color: rgba($color: #000000, $alpha: 0.4);
        font-weight: 500;
        margin-left: 20px;
        font-size: 16px;

        & b {
            color: rgba($color: #000000, $alpha: 0.7);
        }
    }
}

.info_container {
    width: 100%;
    gap: 20px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;

    & .swiper_container {
        min-width: 400px;
        width: 100%;

        & .slide {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            & img {
                object-fit: contain;
                max-width: 100%;
                // max-height: 100%;
                // width: 100% !important;
                // height: auto !important;
            }
        }
    }

    & .text {
        display: flex;
        flex-direction: column;
        gap: 20px;
        padding: 20px 20px;

        & .author_container {
            display: flex;
            flex-direction: row;
            align-items: center;

            & .avatar {
                width: 40px;
                height: 40px;
                border-radius: 50%;
                background-color: rgba(#000000, 0.1);
                display: flex;
                justify-content: center;
                align-items: center;

                & svg {
                    color: #006eff;
                }
            }
        }

        & .line {
            margin-top: -10px;
            height: 1px;
            width: 400px;
            background-color: rgba(#000000, 0.2);
        }

        & .text_container {
            display: flex;
            flex-direction: column;
            gap: 10px;
        }

        & span {
            text-indent: 20px;
            font-size: 16px;
        }

        & .video_container {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 10px;

            & span {
                font-size: 24px;
                font-weight: 600;
            }

            & .video {
                height: 400px;
            }
        }
    }
}

.error {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
}

.otherInfos {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding: 0 20px;
    margin-top: 30px;

    & span {
        font-size: 18px;
        font-weight: 500;
    }

    & div {
        display: flex;
        flex-direction: column;
        gap: 20px;

        & span {
            font-size: 16px;
            font-weight: normal;
        }
    }
}
