.wrapper {
    background-color: #f0f0f0;
    position: relative;
    width: 60vw;
    height: 69vh;
    overflow-y: auto;
    border-radius: 3px;
    color: rgb(37, 37, 37);
    font-size: 14px;
    border-radius: 5px;
    padding: 20px 0;

    & div {
        padding: 0;
    }
}

.menu_button {
    width: 100%;
    padding-left: 50px;
    position: absolute;
    top: 0;
    height: 1px;
    overflow-y: visible;
    z-index: 1000;

    & button {
        position: absolute;
        transform: translateY(-50%);
    }
}
