@import "../style.modules.scss";

.wrapper {
    padding: 0;

    & .theme_selector_container {
        width: 100%;
        height: 100%;
        position: relative;
    }
}

.main {
    overflow-y: hidden;
    border-radius: 3px;
}

.back_button {
    position: absolute;
    left: 120px;
    top: 0px;
    transform: translateY(-50%);
    min-width: 100px;
    display: flex;
    justify-content: center;
}
