.wrapper {
    padding: 0;
}

.main {
    overflow-y: auto;
    overflow-x: hidden;
    width: 100%;
    height: 100%;
    padding: 50px 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
