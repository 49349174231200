.wrapper {
    background-image: url("../../img/background.jpg");
    background-size: cover;
    background-repeat: repeat-y;
    background-position: center;
    min-width: 900px;
    min-height: 100vh;
    height: 100%;
    z-index: -3;
    display: flex;
    flex-direction: column;
    position: fixed;
    height: 100%;
    width: 100%;
    & .header {
        height: 140px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;
        & .header_top {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            & .name {
                color: white;
                font-weight: 700;
                font-size: 46px;
            }
            & .courseTitle {
                margin-top: 5px;
                font-size: 20px;
                color: white;
            }
        }
        & .avatar {
            position: relative;
            width: 120px;
            height: 120px;
            background-color: #ffffff;
            border-radius: 50%;
            overflow: hidden;
            margin-left: 20px;
            & img {
                width: 100%;
                object-fit: scale-down;
                background-color: rgb(192, 189, 172);
            }
            & svg {
                position: absolute;
                height: 60%;
                width: 60%;
                transform: translate(-50%, -50%);
                top: 50%;
                left: 50%;
            }
        }
        & .exit {
            position: absolute;
            transform: translateY(-50%);
            top: 75px;
            right: 90px;
            cursor: pointer;
            min-width: 70px;
            padding: 0 10px;
            height: 30px;
            border-radius: 5px;
            font-size: 16px;
            font-weight: 500;
            color: #fff;
            background-color: #e22c2c;
            transition: 0.3s;
            &:hover {
                background-color: #c21616;
            }
        }
    }
    & .content {
        height: calc(100% - 140px);
        width: 100%;
        z-index: 1000;
    }

    & .reviews_container {
        z-index: 100000;
        position: absolute;
        top: 90px;
        right: 350px;
        background-color: #ffffff;
        box-shadow: 0 5px 10px rgba($color: #000000, $alpha: 0.1);
        width: 50%;
        min-width: 500px;
        max-width: 650px;
        height: 85%;
        border-radius: 10px;
        overflow: hidden;

        & .header {
            position: relative;
            margin: 0;
            width: 100%;
            height: 50px;
            display: flex;
            flex-direction: row;

            & .title {
                display: flex;
                flex-direction: row;
                gap: 10px;
                align-items: center;
                justify-content: center;

                & div {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: center;
                    font-size: 16px;

                    & span {
                        font-size: 16px;
                        color: black;
                    }

                    & svg {
                        color: rgba($color: #ffd700, $alpha: 1);
                        fill: rgba($color: #ffd700, $alpha: 1);
                    }
                }

                & span {
                    font-size: 18px;
                    color: black;
                    font-weight: 500;
                }
            }

            & .close {
                position: absolute;
                color: #e22c2c;
                right: 10px;
                top: 10px;

                &:hover {
                    cursor: pointer;
                }
            }
        }

        & .reviews {
            width: 100%;
            height: calc(100% - 50px);
            box-shadow: inset 0 5px 10px rgba($color: #000000, $alpha: 0.1);
        }
    }
}
