.container {
    $width: 340px;

    width: $width;
    height: $width;

    & .head {
        width: 100%;
    }
}

// @media (max-width: 1600px) {
//     .head {
//         width: 250px;
//     }
// }
