.container {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    & .rating {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 20px;

        & span {
            font-size: 20px;
            font-weight: 600;
        }

        & .stars {
            display: flex;
            flex-direction: row;
            gap: 5px;

            & .container {
                width: 40px;
                height: 40px;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            & .star {
                transition: color 0.1s, fill 0.4s, width 0.3s, height 0.3s;
                color: #006eff;

                &__selected {
                    color: rgba($color: #ffd700, $alpha: 1);
                    fill: rgba($color: #ffd700, $alpha: 1);
                }
            }
        }

        & .comment {
            resize: none;
            border-radius: 5px;
            width: 350px;
            border-color: #006eff;
            border-width: 2px;
            border-style: solid;
            height: 100px;
            padding: 10px;
            font-size: 18px;
        }
    }
}
