.wrapper {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-top: 20px;
    height: 100%;
    width: 100%;
    min-width: 880px;
    overflow: hidden;
}

.leftBlock {
    display: flex;
    flex-direction: column;
    width: 35vw;
    align-items: center;
    margin-left: 50px;
    height: 100%;
    @media (max-height: 800px) {
        width: 30vw;
    }

    & .avatar {
        @media (max-height: 800px) {
            width: 240px;
            height: 240px;
        }
    }
    /* justify-content: space-between; */
}

.transcript {
    overflow: hidden;
    height: 500px;
    color: #fff;
    font-size: 30px;
    margin-bottom: 10px;
}

.footer {
    position: absolute;
    bottom: 15px;
    left: 15px;
    font-size: 20px;
    font-weight: 500;
    color: #fff;
}

@media (max-width: 1115px) {
    .leftBlock {
        width: 25vw;
    }
}

@media (max-width: 1280px) {
    .leftBlock {
        height: calc(100vh - 200px);
    }
}
