.container {
    margin-top: 20px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 30px;
}

.button {
    font-size: 14px;
    height: 40px;
    width: 250px;
}

.answers {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;

    & .answer {
        display: flex;
        flex-direction: column;
        width: 100%;
        align-items: center;
        gap: 10px;
        border-radius: 20px;
        // border: solid 1px black;
        padding: 15px;
        box-shadow: 0px 0px 10px rgba($color: #000000, $alpha: 0.1);

        & .header {
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            gap: 20px;

            & div {
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: 20px;

                & div {
                    display: flex;
                    flex-direction: row;
                    gap: 10px;

                    & .button {
                        padding: 10px;
                        width: auto;

                        &__editor {
                            background-color: #23a6d5;
                            display: flex;
                            flex-direction: row;
                            gap: 10px;
                        }

                        &__delete {
                            display: flex;
                            flex-direction: row;
                            gap: 10px;
                        }
                    }
                }

                & .button {
                    padding: 10px;
                    width: auto;

                    &__delete {
                        display: flex;
                        flex-direction: row;
                        gap: 10px;
                    }
                }
            }
        }

        &_is_correct {
            width: 20px !important;
            height: 20px !important;
        }

        &__correct {
            background-color: rgba(24, 250, 118, 0.1);
        }

        & .text_editor {
            background-color: white;
        }

        &_input {
            padding: 5px 10px !important;
            height: auto !important;
            font-size: 20px;
            width: 100% !important;
        }
    }

    & .icon {
        cursor: pointer;
    }
}
