@import "../../style.modules.scss";
@import "../style.module.scss";

.buttons {
    position: absolute;
    top: 0;
    left: 130px;
    transform: translateY(-50%);
    display: flex;
    flex-direction: row;
    gap: 10px;

    & button {
        display: flex;
        justify-content: center;
        padding: 0 15px;
        gap: 5px;
    }
}

.main {
    display: flex;
    flex-direction: column;
    gap: 20px;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;

    & span {
        width: 100%;
        text-align: center;
        font-size: 24px;
        font-weight: 500;
    }

    & textarea {
        padding: 10px;
        font-size: 20px;
        width: 100%;
        height: 80%;
        resize: none;
        border-radius: 5px;
        border-color: black;
        border-style: solid;
        border-width: 1px;
    }

    & .save {
        padding: 25px 40px;
        font-size: 24px;
        background-color: #006eff;
    }
}
