.button {
  cursor: pointer;
  min-width: 70px;
  padding: 0 10px;
  height: 30px;
  border-radius: 5px;
  font-size: 16px;
  font-weight: 500;
  color: #fff;
  background-color: #e22c2c;
  transition: 0.3s;
  display: flex;
  align-items: center;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.4);
}

.button:hover {
  background-color: #c21616;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.4);
}

.button:disabled,
.button[disabled] {
  background-color: rgb(245, 126, 126);
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.4);
}
