.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    & .mil {
        width: 15%;
    }
    & .main {
        width: 70%;
        background-image: url("../../../img/header.png");
        background-position: center;
        background-repeat: no-repeat;
        background-size: 100%;
        display: flex;
        justify-content: space-around;
        align-items: center;
        padding: 0 30px;
        & .description {
            display: flex;
            flex-direction: column;
            align-items: center;
            & .alesha {
                color: #ffffff;
                font-size: 20px;
                letter-spacing: 1px;
                border-bottom: 1px solid rgba(255, 251, 251, 0.205);
            }
            & .student_first {
                color: #ffffff;
                text-align: center;
                margin-top: 15px;
                font-size: 1.5rem;
            }
            & .student {
                color: #ffffff;
                text-align: center;
                font-size: 1.8rem;
                margin-top: 8px;
            }
        }
        & .type_select_container {
            margin-left: 30px;
            margin-top: 70px;
            & .select_title {
                color: #ffffff;
                font-size: 14px;
                /* text-decoration: underline; */
            }
            & .type_select {
                /* background-color: white; */
                height: 30px;
                width: 140px;
            }
        }
        & .avatar {
            width: 7vw;
            height: 7vw;
            background-color: #ffffff;
            border-radius: 50%;
            overflow: hidden;
            margin-right: 20px;
        }
        .avatar {
            & img {
                /* height: 100%; */
                width: 100%;
                object-fit: fill;
                background-color: rgb(233, 234, 235);
            }
        }
        & .settings {
            display: inline-block;
            position: relative;
            width: 40px;
            &:hover {
                .settings_view {
                    position: absolute;
                    display: flex;
                }
            }
            & .settings_view {
                box-shadow: 0px 0px 3px 1px rgba(0, 0, 0, 0.2);
                background-color: white;
                top: -10px;
                left: 25px;
                display: none;
                flex-direction: column;
                gap: 10px;
                padding: 15px 25px;
                border-radius: 5px;
                z-index: 10000;
                & .setting {
                    &:not(:last-child) {
                        border-bottom: 1px solid rgba(0, 0, 0, 0.2);
                        padding-bottom: 10px;
                    }
                    white-space: nowrap;
                    font-size: 18px;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    gap: 10px;
                    &:hover {
                        cursor: pointer;
                    }
                    &__disabled {
                        & span,
                        svg {
                            opacity: 0.5;
                        }
                    }
                    &__erroe {
                        color: red;
                    }
                    & .loader {
                        height: 20px;
                        width: 20px;
                        & div {
                            top: -8px;
                            left: -8px;
                        }
                    }
                }
            }
        }
    }
    .logoLms {
        width: 15%;
    }
}