/* chart */

.index {
    color: #333333;
    font-size: 14px;
}

.fillCircle {
    fill: #ffb40a;
    cursor: pointer;
}

.fillCircle-completed {
    fill: #64b927;
    cursor: pointer;
}

.shape {
    fill: rgba(69, 42, 131, 0.02);
    /* opacity: 0.05; */
    /* border-width: 2px;
    border-style: solid;
    border-color: #64b927; */
    stroke: #462b83;
    stroke-width: 1px;
    border-radius: 0%;
}

.shape:hover {
    fill: #462b83;
    opacity: 0.35;
    z-index: 100;
}

.scale {
    /* fill: #fafafa; */
    stroke: #462b83;
    stroke-width: 0.15;
}

.axis {
    stroke: #462b83;
    stroke-width: 0.15;
}

.icon {
    /* background-color: #ffb40a; */
    border-radius: 50%;
    cursor: pointer;
}

.caption {
    fill: #462b83;
    font-weight: 400;
    font-size: 12px;
    font-weight: 600;
}

.caption-completed {
    fill: #462b83;
    font-weight: 400;
    font-size: 12px;
    font-weight: 600;
}

#chart svg {
    /* width: 550px !important; */
    padding: 0 !important;
}

.name_div {
    position: absolute;
    min-width: 100%;
    width: auto;
    height: auto;
    cursor: pointer;
    /* background-color: green; */
}

.name_div .text {
    width: 100%;
    font-size: 12px;
    color: #462b83;
    text-align: center;
    word-wrap: break-word;
    /* background-color: red; */
}