.answers {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.answer {
    font-size: 14px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 5px;
    border-radius: 5px;
    transition: background-color 1s;
    /* background-color: rgb(103, 228, 30); */
}

.answer:hover {
    background-color: rgba(103, 255, 141, 0.3);
    cursor: pointer;
}

.answer p {
    text-align: start;
}

.answer .result {
    width: 65px;
    text-align: end;
    transition: color 0.2s;
}

.max_correct_answer {
    background-color: rgba(255, 255, 255, 0.2);
}
