#speech.btn {
  border: none;
  padding: 0;
  border-radius: 100%;
  width: 150px;
  height: 150px;
  font-size: 1.5em;
  color: #fff;
  padding: 0;
  margin: 0;
  background: #a3a3a3;
  position: relative;
  display: inline-block;
  line-height: 50px;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-image: none;
}

#speech.btn-animation {
  background: #ff3d7f;
}

#speech img {
  width: 70px;
}

.pulse-ring {
  content: '';
  width: 150px;
  height: 150px;
  border: 5px solid #a3a3a3;
  border-radius: 50%;
  position: absolute;
  top: 0px;
  left: 0px;
}

.pulse-ring-animation {
  animation: pulsate infinite 2s;
  border: 5px solid #ff3d7f;
}

@keyframes pulsate {
  0% {
    -webkit-transform: scale(1, 1);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1.2, 1.2);
    opacity: 0;
  }
}
