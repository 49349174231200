@import "../style.module.scss";

.availableCommands {
    height: 100%;
    flex-direction: column;
    justify-content: space-between;

    & div {
        display: flex;
        flex-direction: column;
        gap: 10px;
    }
}

.expected_responses {
    width: 80%;
    display: flex;
    align-self: center;
    flex-direction: column;
    align-items: flex-start;
    gap: 5px;

    & .header {
        text-align: start;
        font-size: 12px;
        font-weight: normal;
    }

    & .list {
        display: flex;
        flex-direction: row;
        width: 100%;
        gap: 5px;
        overflow-y: scroll;
        overflow-y: hidden;
        padding: 3px 3px;

        & .list_item {
            white-space: nowrap;
            background-color: rgb(51, 135, 247);
            padding: 5px 10px;
            border-radius: 2px;
            margin: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.25);

            & p {
                font-size: 12px;
                font-weight: normal;
            }
        }
    }
}
