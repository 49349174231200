.wrapper {
    color: #ffffff;
    width: 100%;
    /* height: 70vh; */
    height: 77vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    & .container {
        width: 90%;
        display: flex;
        justify-content: center;
        position: relative;

        & .cards {
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
            width: 100%;
            max-width: 900px;
            gap: 10px;
        }

        @media (max-width: 1750px) {
            .cards {
                max-width: 750px;
            }
        }

        @media (max-height: 890px) {
            .cards {
                max-width: 750px;
            }
        }
    }
}

@media (max-width: 1115px) {
    .wrapper {
        width: 35vw;
    }
}

@media (max-height: 890px) {
    .content {
        width: 700px;
    }
}
