.wrapper {
    z-index: 100;
    position: absolute;
    top: -15px;
    left: 1rem;

    & .icon {
        margin-right: 10px;
    }
}
