@import "../style.module.scss";

.data_container {
    & .text {
        font-size: 32px;
    }

    & .scroll_button {
        z-index: 100;
        position: absolute;
        bottom: 10%;
        right: 50px;
        border-radius: 50%;
        background-color: rgb(0, 110, 255);
        width: 60px;
        height: 60px;
        display: flex;
        justify-content: center;
        align-items: center;
        animation: pulse 1s infinite, down 3s infinite;
    }
}

@keyframes down {
    70% {
        bottom: calc(10% - 30px);
    }
}

@keyframes pulse {
    70% {
        box-shadow: 0 0 0 14px rgba(0, 110, 255, 0.1);
    }
    100% {
        box-shadow: 0 0 0 20px rgba(0, 110, 255, 0);
    }
}
