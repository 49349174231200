.container {
    margin: 30px 30px;
    display: flex;
    width: calc(100% - 60px);
    height: calc(100% - 60px);
    padding: 15% 5%;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    & p {
        white-space: pre-line;
    }

    & .button {
        width: 300px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;

        &__next {
            background-color: rgb(0, 110, 255);
        }
    }
}

.reset_icon {
    position: absolute;
    top: 0;
    left: 0;
}

/*# sourceMappingURL=style.module.css.map */

.buttons {
    display: flex;
    flex-direction: column;
    gap: 10px;
}
