.audios {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 20px;
    width: calc(2 * 20px + 3 * 400px);
    margin-left: calc((100% - 2 * 20px - 3 * 400px) / 2);

    @media (max-width: 1640px) {
        width: calc(2 * 20px + 2 * 400px);
        margin-left: calc((100% - 1 * 20px - 2 * 400px) / 2);
    }

    & .audio {
        position: relative;
        display: flex;
        flex-direction: column;
        background-color: rgba(#006eff, 0.8);
        box-shadow: 0 0 5px rgba(black, 0.1);
        width: 400px;
        justify-content: space-between;
        gap: 15px;
        padding-top: 20px;
        border-radius: 16px;
        align-items: center;
        // padding: 12px 20px;
        font-size: black;

        & span {
            color: white;
            font-size: 20px;
            text-align: center;
        }

        & audio::-webkit-media-controls-panel {
            // background-color: rgba(#006eff, 0);
            background-color: white;
        }
        & audio {
            height: 45px;
            width: 100%;
            background-color: white;
            border-radius: 0 0 15px 15px;
            // border-radius: 15px;
        }

        & .delete_button {
            $width: 35px;
            width: $width;
            height: $width;

            position: absolute;
            left: calc(100% - $width);
            top: 0;

            display: flex;
            align-items: center;
            justify-content: center;

            & svg {
                color: rgba(#e62b59, 1);
            }

            &:hover {
                cursor: pointer;
            }
        }

        // audio::-webkit-media-controls-panel
        // audio::-webkit-media-controls-mute-button
        // audio::-webkit-media-controls-play-button
        // audio::-webkit-media-controls-timeline-container
        // audio::-webkit-media-controls-current-time-display
        // audio::-webkit-media-controls-time-remaining-display
        // audio::-webkit-media-controls-timeline
        // audio::-webkit-media-controls-volume-slider-container
        // audio::-webkit-media-controls-volume-slider
        // audio::-webkit-media-controls-seek-back-button
        // audio::-webkit-media-controls-seek-forward-button
        // audio::-webkit-media-controls-fullscreen-button
        // audio::-webkit-media-controls-rewind-button
        // audio::-webkit-media-controls-return-to-realtime-button
        // audio::-webkit-media-controls-toggle-closed-captions-button
    }
}

// .audio {
//     // width: calc(90% - 10px);
//     width: 100%;
//     display: flex;
//     flex-direction: row;
//     align-items: center;

//     & .audio_button {
//         height: 30px;
//         width: 30px;
//         background: linear-gradient(-70deg, #23a6d5, #23d5ab);
//         background-size: 300% 300%;
//         animation: gradient 10s ease infinite;
//         border-radius: 50%;
//         z-index: +2;

//         & .play {
//             position: relative;
//             transform: translate(-50%, -50%);
//             top: 50%;
//             left: 55%;
//         }

//         & .pause {
//             position: relative;
//             transform: translate(-50%, -50%);
//             top: 50%;
//             left: 50%;
//         }

//         & :hover {
//             cursor: pointer;
//         }
//     }

//     & .audio_title {
//         margin-left: 10px !important;
//     }
// }
