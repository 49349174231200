.info {
    display: flex;
    flex-direction: column;
    gap: 0;
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 0 5px rgba(#000000, 0.2);

    & .header {
        gap: 0;

        & .author_container {
            display: flex;
            flex-direction: row;
            align-items: center;

            & .avatar {
                width: 40px;
                height: 40px;
                border-radius: 50%;
                background-color: rgba(#000000, 0.1);
                display: flex;
                justify-content: center;
                align-items: center;

                & svg {
                    color: #006eff;
                }
            }
        }

        & .line {
            margin-top: 5px;
            height: 1px;
            width: 400px;
            background-color: rgba(#000000, 0.2);
        }
    }

    & .extract {
        margin-top: 5px;
        white-space: pre-line;
        font-size: 16px;
        line-height: 21px;
        max-height: 63px;
        height: 100%;
        display: -webkit-box;
        -webkit-line-clamp: 3; // <- you can change rows number
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    & .likes {
        display: flex;
        flex-direction: row;

        & div {
            display: flex;
            flex-direction: row;
            gap: 5px;
            align-items: center;
            font-size: 18px;
            font-weight: 500;
        }
    }

    &:hover {
        cursor: pointer;
    }
}
