.card {
    width: 24%;
    @media (max-width: 1500px) {
        width: 32%;
    }
    background-color: white;
    display: flex;
    border-radius: 10px;
    flex-direction: column;
    padding: 10px;
    gap: 10px;
    margin-top: 15px;
    // height: 265px;

    &:hover {
        cursor: pointer;
    }

    & .preview {
        position: relative;

        & img {
            width: 100%;
            padding: 0;
        }

        & span {
            position: absolute;
            bottom: 0;
            right: 0;
            background-color: rgba($color: #000000, $alpha: 0.7);
            padding: 5px 10px;
            color: rgba($color: #ffffff, $alpha: 0.9);
            font-weight: 600;
        }
    }

    & .info {
        display: flex;
        flex-direction: column;
        gap: 10px;

        & .title {
            font-size: 18px;
            font-weight: 600;
            white-space: pre-line;
            display: -webkit-box;
            -webkit-line-clamp: 2; // <- you can change rows number
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        & .service {
            display: flex;
            flex-direction: row;
            width: 100%;
            gap: 10px;

            & .author_avatar {
                text-decoration: none;
                height: 40px;
                width: 40px;
                background-color: rgba($color: #000000, $alpha: 0.05);
                border-radius: 50%;
                display: flex;
                justify-content: center;
                align-items: center;

                & svg {
                    height: 30px;
                    width: 30px;
                    color: #006eff;
                }
            }

            & .info {
                display: flex;
                flex-direction: column;

                & .name {
                    text-decoration: none;
                    color: black;
                    font-weight: 600;
                }

                & .counts {
                    display: flex;
                    flex-direction: row;
                    gap: 15px;

                    & .count {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        gap: 5px;

                        & svg {
                            width: 15px;
                            height: 15px;
                        }

                        & .view {
                            color: #006eff;
                        }
                        & .like {
                            transition: fill 0.4s ease;
                            color: rgb(255, 57, 90);

                            &__selected {
                                fill: rgb(255, 57, 90);
                            }
                        }
                    }
                }
            }
        }
    }
}
