@import "../style.module.scss";

.expected_responses {
    width: 80%;
    align-self: center;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 5px;

    & .header {
        text-align: start;
        font-size: 12px;
        font-weight: normal;
    }

    & .list {
        display: flex;
        flex-direction: row;
        width: 100%;
        gap: 5px;
        overflow-y: scroll;
        overflow-y: hidden;
        padding: 3px 3px;

        & .list_item {
            white-space: nowrap;
            background-color: rgb(51, 135, 247);
            padding: 5px 10px;
            border-radius: 2px;
            margin: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.25);

            & p {
                font-size: 12px;
                font-weight: normal;
            }
        }
    }
}

.next_question {
    position: absolute;
    bottom: 13px;
    right: 7px;
    transition: transform 0.2s;

    & svg:hover {
        cursor: pointer;
        transform: scale(1.3);
    }

    & p {
        margin: 0;
        padding: 0;
        font-size: 12px;
    }
}

.previous_question {
    position: absolute;
    bottom: 13px;
    left: 7px;
    transition: transform 0.2s;

    & svg:hover {
        cursor: pointer;
        transform: scale(1.3);
    }

    & p {
        margin: 0;
        padding: 0;
        font-size: 12px;
    }
}

.arrow_opacity {
    opacity: 0.5;
}
